<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <div class="auth-page-left">
            <div class="logo">
              <img src="../../../assets/images/logo.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Jewellery</h4>
            </div>

            <div class="auth-page-right-content">
              <div class="heading">
                <h6>Set New Password</h6>
                <p
                  v-if="errorMessage != null"
                  class="tag-line"
                  style="color: red"
                >
                  {{ errorMessage }}
                </p>
                <p>
                  Your identity has been Verified! <br />
                  Set your new Password
                </p>
              </div>
              <form @keyup.enter="newPassword(0)">
                <div class="form-group">
                  <i class="fa fa-lock"></i>
                  <input
                    :type="type1"
                    class="form-control"
                    id="password"
                    placeholder="New Password"
                    autocomplete="off"
                    v-on:keyup="errorNull"
                    v-model.trim="$v.details.password.$model"
                    :class="{
                      'is-invalid': $v.details.password.$error,
                      'is-valid': !$v.details.password.$invalid,
                    }"
                  />
                  <i
                    v-html="btnText"
                    id="togglePassword"
                    @click="showPassword"
                  ></i>
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.password.required"
                      >Password is required</span
                    >
                    <span v-if="!$v.details.password.minLength"
                      >Password must have at least
                      {{ $v.details.password.$params.minLength.min }}
                      letters</span
                    >
                    <span v-if="!$v.details.password.maxLength"
                      >Password must have at most
                      {{ $v.details.password.$params.maxLength.max }}
                      letters</span
                    >
                  </div>
                </div>
                <div class="form-group">
                  <i class="fa fa-lock"></i>
                  <input
                    :type="type2"
                    class="form-control"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    autocomplete="off"
                    v-on:keyup="errorNull"
                    v-model.trim="$v.details.confirmPassword.$model"
                    :class="{
                      'is-invalid': $v.details.confirmPassword.$error,
                      'is-valid': !$v.details.confirmPassword.$invalid,
                    }"
                  />
                  <i
                    v-html="btnText2"
                    id="toggleConfirmPassword"
                    @click="showConfirmPassword"
                  ></i>
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.confirmPassword.required"
                      >Confirm Password is required</span
                    >
                    <span v-else>Passwords must be identical.</span>
                  </div>
                </div>
                <button
                  type="button"
                  class="auth-btn"
                  @click="newPassword(0)"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Next</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  name: "NewPassword",

  components: {},

  data: function() {
    return {
      errorMessage: null,
      details: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      type1: "password",
      type2: "password",
      btnText: '<i class="fa fa-eye-slash"></i>',
      btnText2: '<i class="fa fa-eye-slash"></i>',
      isLoadingArray: [],
    };
  },
  validations: {
    details: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(15),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["changePassword"]),

    newPassword: function(index) {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.details.code = localStorage.getItem("verifiedCode");
      this.details.email = localStorage.getItem("verifyEmail");
      this.changePassword(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.$router.push({ name: "PasswordUpdate" });
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },

    showPassword: function() {
      if (this.type1 === "password") {
        this.type1 = "text";
        this.btnText = "<i class='fa fa-eye'></i>";
      } else {
        this.type1 = "password";
        this.btnText = "<i class='fa fa-eye-slash'></i>";
      }
    },

    showConfirmPassword: function() {
      if (this.type2 === "password") {
        this.type2 = "text";
        this.btnText2 = "<i class='fa fa-eye'></i>";
      } else {
        this.type2 = "password";
        this.btnText2 = "<i class='fa fa-eye-slash'></i>";
      }
    },
    errorNull() {
      this.errorMessage = null;
    },
  },
};
</script>
