var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-page"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row no-gutters"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"auth-page-right"},[_vm._m(1),_c('div',{staticClass:"auth-page-right-content"},[_c('div',{staticClass:"heading"},[_c('h6',[_vm._v("Set New Password")]),(_vm.errorMessage != null)?_c('p',{staticClass:"tag-line",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_vm._m(2)]),_c('form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.newPassword(0)}}},[_c('div',{staticClass:"form-group"},[_c('i',{staticClass:"fa fa-lock"}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.password.$model),expression:"$v.details.password.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.password.$error,
                    'is-valid': !_vm.$v.details.password.$invalid,
                  },attrs:{"type":_vm.type1,"id":"password","placeholder":"New Password","autocomplete":"off"},domProps:{"value":(_vm.$v.details.password.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('i',{attrs:{"id":"togglePassword"},domProps:{"innerHTML":_vm._s(_vm.btnText)},on:{"click":_vm.showPassword}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.password.required)?_c('span',[_vm._v("Password is required")]):_vm._e(),(!_vm.$v.details.password.minLength)?_c('span',[_vm._v("Password must have at least "+_vm._s(_vm.$v.details.password.$params.minLength.min)+" letters")]):_vm._e(),(!_vm.$v.details.password.maxLength)?_c('span',[_vm._v("Password must have at most "+_vm._s(_vm.$v.details.password.$params.maxLength.max)+" letters")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('i',{staticClass:"fa fa-lock"}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.confirmPassword.$model),expression:"$v.details.confirmPassword.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.confirmPassword.$error,
                    'is-valid': !_vm.$v.details.confirmPassword.$invalid,
                  },attrs:{"type":_vm.type2,"id":"confirmPassword","placeholder":"Confirm Password","autocomplete":"off"},domProps:{"value":(_vm.$v.details.confirmPassword.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.confirmPassword, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('i',{attrs:{"id":"toggleConfirmPassword"},domProps:{"innerHTML":_vm._s(_vm.btnText2)},on:{"click":_vm.showConfirmPassword}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.confirmPassword.required)?_c('span',[_vm._v("Confirm Password is required")]):_c('span',[_vm._v("Passwords must be identical.")])])]),_c('button',{staticClass:"auth-btn",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.newPassword(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Next")])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-6 d-none d-md-block"},[_c('div',{staticClass:"auth-page-left"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../../assets/images/logo.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('h4',[_vm._v("Jewellery")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Your identity has been Verified! "),_c('br'),_vm._v(" Set your new Password ")])
}]

export { render, staticRenderFns }